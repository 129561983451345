import { useEffect, useState } from 'react';

const TEAM_ID = 6;

const InviteUser = ({ jwt, api }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('2010-01-18');
  const [teams, setTeams] = useState();
  const [team, setTeam] = useState(TEAM_ID);
  const [loading, setLoading] = useState(false);


  // Fetch team list
  useEffect(() => {
    fetch(`${api}/teams`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    }).then(res => {
      if (res.status !== 200) alert('Error fetching teams');

      res.json().then(body => {
        setTeams(body.map(team => ({ id: team.id, type: team.type, club: team.club.name }) ));
      });

    });
  }, [api, jwt]);

  const sendInvite = async () => {
    setLoading(true);

    const response = await fetch(`${api}/onboard`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`
      },
      body: JSON.stringify([{
        teamId: team,
        firstName: name,
        birthdate: birthdate,
        parentEmail: email,
      }])
    });
    
    if (response.status === 204) {
      setName('');
      setEmail('');
      alert('Invite sent!');
    } else {
      alert('Something went wrong');
    }

    setLoading(false);
  };

  if (!teams) return <h3>loading teams...</h3>;

  teams.sort((a, b) => {
    if (a.id === TEAM_ID) return -1;
    if (b.id === TEAM_ID) return 1;
    a.type.localeCompare(b.type);
  });
  
  const teamsList = teams.map(team =>
    <option
      key={team.id}
      value={team.id}
    >
      {team.type} ({team.club})
    </option>
  );

  return (
    <div>
      <h1>Send Invite</h1>
      <div>
        Team &nbsp;&nbsp;
        <select onChange={e => setTeam(e.target.value)}>
          {teamsList}
        </select>
      </div>

      <div>
        First Name &nbsp;&nbsp;
        <input onChange={e => setName(e.target.value)} value={name} />
      </div>

      <div>
        Birthdate (YYYY-MM-DD) &nbsp;&nbsp;
        <input onChange={e => setBirthdate(e.target.value)} value={birthdate} />
      </div>

      <div>
        Parent Email &nbsp;&nbsp;
        <input onChange={e => setEmail(e.target.value)} value={email} />
      </div>

      <button onClick={() => sendInvite()}>Send Invite</button>
      <br/>
      {loading && 'Sending...'}
    </div>
  );
};


export default InviteUser;