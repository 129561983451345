import { useState } from 'react';


const Login = ({ onLogin, api }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const loginToApi = async (username, password) => {

    const response = await fetch(`${api}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });
    
    if (response.status === 200) {
      const jwt = response.headers.get('Authorization').split(' ')[1];

      // decode jwt, get role
      const role = JSON.parse(atob(jwt.split('.')[1])).role;
      if (role !== 'SITE_ADMIN') {
        alert('Not permitted');
        setUsername('');
        setPassword('');
        return;
      }
      onLogin(jwt);
    } else {
      alert('Login failed');
    }

  };

  return (
    <div>
      <h1>Login</h1>

      <div>
        username &nbsp;&nbsp;
        <input onChange={e => setUsername(e.target.value)} value={username} />
      </div>
      
      <div>
        password &nbsp;&nbsp;
        <input type="password" onChange={e => setPassword(e.target.value)} value={password} />
      </div>

      <button onClick={() => loginToApi(username, password)}>Login</button>
    </div>
  );
};


export default Login;