import './App.css';
import { useState } from 'react';
import Login from './components/Login/Login';
import InviteUser from './components/InviteUser/InviteUser';

const API = 'https://api.ops.proinstinctapp.com';
function App() {

  const [jwt, setJwt] = useState();

  return (
    <div className="App">
      {!jwt && <Login onLogin={setJwt} api={API} />}
      {jwt  && <InviteUser jwt={jwt} api={API} />}
    </div>
  );
}

export default App;
